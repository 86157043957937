(function($, window, document) {
    "use strict";
    $(function() {
        //##Variables
        var $body = $('body'),
            $window = $(window),
            $doc = $(document),
            $tabHeader = $('.tab_header'),
            $tabHeaderStatusi = $('.tab_header_statusi'),
            $prioritetiRight=$('.prioriteti_rightside'),
            $kontriboRight = $('#kontribo_right'),
            defaultEasing = [0.4, 0.0, 0.2, 1];
        //End Variables

        // go top button
        $(document).scroll(function() {
            if ($(document).scrollTop() >= $('#header').outerHeight()) {
                $('#myBtn').show();
            } else {
                $('#myBtn').hide();
            }
        });
        $('#myBtn').click(function() {
            $("html, body").animate({
                   scrollTop: (0)
           }, 1000);
        });
    // ----Hamburger menu---//
    $('#nav_btn').click(function(e) {
        e.preventDefault();
        $(this).toggleClass("active");
        $(".hamburger_menu_toggle").stop().slideToggle(450);        
    });

    $doc.mouseup(function(e) {
        var container = $('.hamburger_menu_toggle,#nav_btn');
        if (!container.is(e.target) && container.has(e.target).length === 0) {
            $('.hamburger_menu_toggle').slideUp();
            $('#nav_btn').removeClass("active");
        }

        var search_container =  $('#search_form, .search_icon');
        if (!search_container.is(e.target) && search_container.has(e.target).length === 0) {
            $("#search_form").css('display','none');
        }
    });
    
     // ----Search button---//
     $('.search_icon').click(function(e) {
        e.preventDefault();
        $("#search_form").css('display','block');        
    });

    $(document).keyup(function(e) {
        if (e.keyCode === 27) {
            $("#search_form").css('display','none');        
        }
      });

    // Custom Tabs
    $tabHeader.click(function(e){
        if(!$(this).hasClass('active')) {
            var mainParent = $(this).parent().parent().parent(),
            currentID = $(this).attr("data-tab-header"),
            currentContainer = mainParent.find('.tabs_content[data-tab-content="'+currentID+'"]');
            
            $(this).siblings().removeClass('active');
            $(this).addClass('active');

            currentContainer.siblings().removeClass('active');
            currentContainer.addClass('active');
        }
    });

    // Custom Tabs Statusi
    $tabHeaderStatusi.click(function(e){
        if(!$(this).hasClass('active')) {
            var mainParent = $(this).parent().parent().parent().parent(),
            currentID = $(this).attr("data-tab-header"),
            currentContainer = mainParent.find('.tabs_content_status[data-tab-content="'+currentID+'"]');
            
            $(".tab_header_statusi.active").removeClass('active');
            $(this).addClass('active');

            currentContainer.siblings().removeClass('active');
            currentContainer.addClass('active');
        }
    });
  
    $('#different_statuti').click(function(e) {
        e.preventDefault();
        if ($(window).width() <= 1024) {
            $(this).parent().find('.hide_statuti').slideToggle('', function() {
                if($(this).is(':visible')){
                    $('#different_statuti').children('a').find('.fa-angle-down').css('transform','rotate(180deg)');
                } else {
                    $('#different_statuti').children('a').find('.fa-angle-down').css('transform','rotate(0deg)');
                }
            });
        }
    });

    $('.hide_statuti').click(function(e) {
        e.preventDefault();
        if ($(window).width() <= 1024) {
            $('.hide_statuti').slideToggle('', function() {
                if($(this).is(':visible')){
                    $('#different_statuti').children('a').find('.fa-angle-down').css('transform','rotate(180deg)');
                } else {
                    $('#different_statuti').children('a').find('.fa-angle-down').css('transform','rotate(0deg)');
                }
            });
        }
    });

    // Prioritetet
    $prioritetiRight.click(function(e){
        if(!$(this).hasClass('active')) {
            var mainParent = $(this).parent().parent().parent().parent(),
            currentID = $(this).attr("data-tab-header"),
            allContainer = mainParent.find('.prioriteti_heading'),
            currentContainer = mainParent.find('.prioriteti_heading[data-tab-content="'+currentID+'"]');

            $(".prioriteti_rightside.active").removeClass('active');
            $(this).addClass('active');

            allContainer.removeClass('active_pr');
            currentContainer.addClass('active_pr');
        }
    });

    $('#different').click(function(e) {
        e.preventDefault();
        if ($(window).width() <= 1024) {
            $(this).parent().find('.hide_prioritetet').slideToggle('', function() {
                if($(this).is(':visible')){
                    $('#different').children('a').find('.fa-angle-down').css('transform','rotate(180deg)');
                } else {
                    $('#different').children('a').find('.fa-angle-down').css('transform','rotate(0deg)');
                }
            });
        }
    });
    
    // -----Pagination-----//
    var header = document.getElementsByClassName("page_list");
    var btns = document.getElementsByClassName("btn");
    for (var i = 0; i < btns.length; i++) {
      btns[i].addEventListener("click", function() {
        var current = document.getElementsByClassName("active");
        current[0].className = current[0].className.replace(" active", "");
        this.className += " active";
      });
    }

    // ----Accordion script----//

    $('.toggle').click(function(e) {
        e.preventDefault();
        var $this = $(this);
        $('.toggle').find('i').addClass('fa-plus');
        $('.toggle').find('i').removeClass('fa-minus');
        $('.toggle').parent().removeClass('is--active');
        $this.parent().addClass('is--active');
        
    
      if ($this.next().hasClass('show')) {
          $this.next().removeClass('show');
          $this.next().slideUp(350);
          $this.find('i').addClass('fa-plus');
          $this.find('i').removeClass('fa-minus');
          $this.parent().removeClass('is--active');

      } else {
          $this.parent().parent().find('li .inner').removeClass('show');
          $this.parent().parent().find('li .inner').slideUp(350);
          $this.next().toggleClass('show');
          $this.next().slideToggle(350);
          $this.find('i').removeClass('fa-plus');
          $this.find('i').addClass('fa-minus');
      }

    });
    // Accordion on hamburger menu ( mobile/tablet)
    $('.toggle_hamburger_menu').click(function(e) {
        e.preventDefault();
        var $this = $(this);
        $('.toggle_hamburger_menu').find('i').addClass('fa-plus');
        $('.toggle_hamburger_menu').find('i').removeClass('fa-minus');
        $('.toggle_hamburger_menu').parent().removeClass('is--active');
        $this.parent().addClass('is--active');
        
    
      if ($this.next().hasClass('show')) {
          $this.next().removeClass('show');
          $this.next().slideUp(350);
          $this.find('i').addClass('fa-plus');
          $this.find('i').removeClass('fa-minus');
          $this.parent().removeClass('is--active');

      } else {
          $this.parent().parent().find('li .inner_list').removeClass('show');
          $this.parent().parent().find('li .inner_list').slideUp(350);
          $this.next().toggleClass('show');
          $this.next().slideToggle(350);
          $this.find('i').removeClass('fa-plus');
          $this.find('i').addClass('fa-minus');
      }

    });

    //Forma- Antaresimit

    $('.vazhdo_buttoni').on('click', function(){
        var isValid = true;
        $(".antaresohu_1 input").each(function() {
            var element = $(this);
            if (element.val().length === 0) {
                isValid = false;
                console.log(element.val().length);
            }else{
                isValid = isValid && true; 
            }
        });
        $(".antaresohu_1 select").each(function() {
            var element = $(this);
            if (!element.val()) {
                isValid = false;
            }else{
                isValid = isValid && true; 
            }
        });
        
        if(isValid && $('.antaresohu_2').hasClass('is--hidden')){
            $('.antaresohu_1').addClass('is--hidden');
            $('.antaresohu_2').removeClass('is--hidden');
            $('.vazhdo_buttoni').addClass('revert');
            $('.vazhdo_buttoni').text('Kthehu').css('background-color', '#666');
        }
        else if($('.antaresohu_1').hasClass('is--hidden')){
            $('.antaresohu_2').addClass('is--hidden');
            $('.antaresohu_1').removeClass('is--hidden');
            $('.vazhdo_buttoni').removeClass('revert');
            $('.vazhdo_buttoni').text('Vazhdo').css('background-color', '#e2001a');
        }
    });
 
    // Range Slider
    if( document.getElementById("myRange")) {
    var min = document.getElementById("myRange").min;
}
    $('#demo').html(min);
    $(document).on('input', '#myRange', function() {
        $('#demo').html($(this).val());
    });
    //Kontribo right
    var thisUrl = window.location.href;
    $('.radio_right').change(function() {
        thisUrl = $(this).val();
    });
    $kontriboRight.submit(function( event ) {
        event.preventDefault();
        window.location.href = thisUrl;
      });
    // ------Facebook plugin----//
    (function(d, s, id) {
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s); js.id = id;
        js.src = 'https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v3.2';
        fjs.parentNode.insertBefore(js, fjs);
      }(document, 'script', 'facebook-jssdk'));
      
        
        console.log("%c Developed by: Kutia Digital Agency http://kutia.net", "padding:9px 5px; background:#3c4952; line-height:25px;color: #59bf9d");
    });
}(window.jQuery, window, document));
